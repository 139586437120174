* {
  margin: 0px; 
  padding: 0px;
}

html,
body {
  margin: 0px;
  padding: 0px;
  height: 100%;
  min-height: 100%;
}

body {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% auto;
  background-image: url('/background.jpg');
  position: relative;
}

.App {
  padding-bottom: 64px;
}

.App-container {
  width: 100%;
  max-width: 90%;
  margin: 0px auto;
}

.App-header {
  width: 100%;
  min-height: 64px;
  position: relative;
  top: 0px;
  box-shadow: 0px 1px 4px rgba(0,0,0,.6);
  background-color: transparent;
  z-index: 10;
}

.App-logo {
  display: inline-block;
  max-width: 130px;
  max-height: 44px;
  margin-top: 14px;
}

.App-menu {
  display: none;
  float: right;
  height: 64px;
}

.App-menu li {
  display: inline-block;
  height: 64px;
  line-height: 64px;
  padding-left: 15px;
  padding-right: 15px;
  transition: background .3s;
}

.App-menu li a {
  color: #00a99d;
}

.App-menu li a.secondary {
  color: rgba(0, 0, 0, .8);
  text-decoration: none;
}

.App-menu li:hover {
  background-color: rgba(0, 0, 0, .05);
}

.App-menu-movil {
  float: right;
  height: 64px;
}

.App-menu-movil li {
  display: inline-block;
  height: 64px;
  line-height: 64px;
  padding-left: 15px;
  padding-right: 15px;
}

.App-menu-movil li a {
  color: white;
}

.App-menu-movil li img {
  max-width: 24px;
}

.App-Background-yellow {
  position: absolute;
  top: -5%;
  left: 0;
  width: 100%;
  height: 860px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('/background-yellow.png');
  z-index: 3;
  -webkit-filter: drop-shadow(0px 5px 21px rgba(0, 0, 0, .6));
  filter: drop-shadow(0px 5px 21px rgba(0, 0, 0, .6));
}

.App-Background-green {
  position: absolute;
  top: -50%;
  left: 0;
  width: 100%;
  height: 860px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('/background-green.png');
  z-index: 4;
  -webkit-filter: drop-shadow(0px 5px 21px rgba(0, 0, 0, .6));
  filter: drop-shadow(0px 5px 21px rgba(0, 0, 0, .6));
}

.App-Elements {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 64px;
  width: 100%;
  height: calc(100% - 64px);
  display: none;
}

.App-Elements .element {
  width: 12px;
  height: 12px;
  position: absolute;
  top: 10%;
  left: 10%;
  z-index: 4;
  -webkit-filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, .54));
  filter: drop-shadow(0px 1px 10px rgba(0, 0, 0, .54));
  opacity: .8;
  transition: all 1s;

  /*animation-name: element_animation;
  animation-duration: 4s;
  animation-iteration-count: infinite;*/
}

.App-Elements .element.cubic.width-1 {
  width: 24px;
  height: 24px;
}

.App-Elements .element.cubic.width-2 {
  width: 48px;
  height: 48px;
}

.App-Elements .element.cubic {
  background-color: #00a99d;
  background: linear-gradient(90deg, rgba(34, 181, 115,1) 0%, rgba(34, 181, 115, 1) 35%, rgba(0, 169, 157, 1) 100%);
}

.App-Elements .element.triangle-1 {
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent transparent rgb(223 202 79) transparent;
}

.App-Elements .element.triangle-1.width-1 {
  border-width: 0 12px 24px 12px; 
}

.App-Elements .element.triangle-1.width-2 {
  border-width: 0 24px 48px 24px; 
}

.App-Elements .element.triangle-2 {
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent transparent transparent rgba(34, 181, 115,1);
}

.App-Elements .element.triangle-2.width-1 {
  border-width: 12px 0 12px 24px;; 
}

.App-Elements .element.triangle-2.width-2 {
  border-width: 24px 0 24px 48px;; 
}

.App-Elements .element.triangle-3 {
  width: 0;
  height: 0;
  border-style: solid;
  border-color: rgb(223 202 79) transparent transparent transparent;
}

.App-Elements .element.triangle-3.width-1 {
  border-width: 24px 12px 0 12px;
}

.App-Elements .element.triangle-3.width-2 {
  border-width: 48px 24px 0 24px; 
}

.App-Elements:hover .element {
  transform: translate(-16px, 26px) rotate(8deg) rotateY(5deg);
}

.App-Elements:hover .element.cubic {
  transform: translate(0px, -26px) rotate(5deg) rotateY(45deg);
}

.App-Elements:hover .element.triangle-1 {
  transform: translate(0px, -6px) rotate(15deg) rotateY(7deg);
}

.App-Elements:hover .element.triangle-2 {
  transform: translate(0px, 16px) rotate(5deg) rotateY(-10deg);
}

.App-Elements:hover .element.triangle-3 {
  transform: translate(0px, 26px) rotate(10deg) rotateY(-25deg);
}

.App-Elements:hover .element.cubic:hover {
  transform: translate(0px, -16px) rotate(10deg) rotateY(15deg);
}

.App-Elements:hover .element.triangle-1:hover {
  transform: translate(0px, -16px) rotate(15deg) rotateY(7deg);
}

.App-Elements:hover .element.triangle-2:hover {
  transform: translate(0px, -16px) rotate(-5deg) rotateY(10deg);
}

.App-Elements:hover .element.triangle-3:hover {
  transform: translate(0px, -16px) rotate(-10deg) rotateY(25deg);
}

@keyframes element_animation {
  0% {transform: translate(0px, -16px) rotate(15deg) rotateY(7deg);}
  50% {transform: translate(0px, -16px) rotate(-10deg) rotateY(25deg);}
  100% {transform: translate(0px, -16px) rotate(15deg) rotateY(7deg);}
}

@keyframes element_animation_2 {
  0% {transform: translate(-16px, 26px) rotate(8deg) rotateY(5deg)}
  50% {transform: translate(0px, -16px) rotate(-5deg) rotateY(10deg);}
  100% {transform: translate(-16px, 26px) rotate(8deg) rotateY(5deg)}
}

.App-Content {
  position: relative;
  left: 0;
  right: 0;
  margin: 0 auto;
  margin-top: 64px;
  z-index: 5;
  max-width: 85%;
  color: white;
}

.App-Content .App-title {
  font-weight: normal;
  font-size: 40px;
}

.App-Content .App-title strong {
  font-weight: bold;
}

.App-Content .App-subtitle {
  font-weight: normal;
  font-size: 18px;
  margin-top: 10px;
}

.App-Content .App-phrase {
  margin-top: 50px;
  border-left: 3px solid #d8c959;
  padding-left: 10px;
}

.App-Phone {
  position: relative;
  top: 14%;
  left: 0;
  right: 0;
  margin: 0 auto;
  margin-top: 64px;
  width: 240px;
  height: 468px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('/phone.png');
  z-index: 4;  
  transition: 1s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.App-Phone:hover {
  /*TODO*/
}

.App-Phone .App-Phone-Waiter {
  position: absolute;
  top: 1%;
  left: 0;
  right: 0;
  height: 99%;
  width: 85%;
  margin: 0 auto;
  border-radius: 40px;
  background-color: rgba(0, 0, 0, .2);
  visibility: hidden;
  opacity: 0;
}

.App-Phone .App-Phone-Waiter.active {
  visibility: visible;
  opacity: 1;
}

.App-Phone .App-Phone-Waiter.active-form .Phone-Waiter-Box .Waiter-Box-Form,
.App-Phone .App-Phone-Waiter.active-loader .Phone-Waiter-Box .Waiter-Box-Loader,
.App-Phone .App-Phone-Waiter.active-success .Phone-Waiter-Box .Waiter-Box-Success {
  visibility: visible;
  opacity: 1;
}

.App-Phone .App-Phone-Waiter .Phone-Waiter-Box .Waiter-Box-Form {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  background-color: white;
  border-radius: 8px;
  width: 80%;
  margin: 0 auto;
  opacity: 0;
  visibility: hidden;
}

.App-Phone .App-Phone-Waiter .Phone-Waiter-Box .Waiter-Box-Form .Waiter-Box-Header {
  padding: 15px 10px;
  padding-bottom: 5px;
  text-align: center;
}

.App-Phone .App-Phone-Waiter .Phone-Waiter-Box .Waiter-Box-Form .Waiter-Box-Header h6 {
  display: block;
  margin-bottom: 4px;
}

.App-Phone .App-Phone-Waiter .Phone-Waiter-Box .Waiter-Box-Form .Waiter-Box-Header small {
  display: block;
  font-size: 8px;
}

.App-Phone .App-Phone-Waiter .Phone-Waiter-Box .Waiter-Box-Form .Waiter-Box-Content {
  padding: 10px;
}

.App-Phone .App-Phone-Waiter .Phone-Waiter-Box .Waiter-Box-Form .Waiter-Box-Content input {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, .2);
  font-size: 10px;
  padding: 5px 5px;
  display: block;
  box-sizing: border-box;
}

.App-Phone .App-Phone-Waiter .Phone-Waiter-Box .Waiter-Box-Form .Waiter-Box-Content input.error {
  border-color: red;
}

.App-Phone .App-Phone-Waiter .Phone-Waiter-Box .Waiter-Box-Form .Waiter-Box-Content input:last-child {
  border-top: 0;
}

.App-Phone .App-Phone-Waiter .Phone-Waiter-Box .Waiter-Box-Form .Waiter-Box-Footer {
  border-top: 1px solid rgba(0, 0, 0, .1);
}

.App-Phone .App-Phone-Waiter .Phone-Waiter-Box .Waiter-Box-Form .Waiter-Box-Footer button {
  width: 50%;
  text-align: center;
  padding: 10px 7px;
  color: #39bbb1;
  border: 0;
  font-size: 10px;
  background-color: transparent;
}

.App-Phone .App-Phone-Waiter .Phone-Waiter-Box .Waiter-Box-Loader {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  background-color: white;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  opacity: 0;
  visibility: hidden;
  text-align: center;
}

.App-Phone .App-Phone-Waiter .Phone-Waiter-Box .Waiter-Box-Loader img {
  width: 15px;
  margin-top: 12px;
}

.App-Phone .App-Phone-Waiter .Phone-Waiter-Box .Waiter-Box-Success {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, .8);
  border-radius: 8px;
  width: 42%;
  margin: 0 auto;
  opacity: 0;
  visibility: hidden;
  text-align: center;
  padding-top: .8rem;
  padding-bottom: .8rem;
}

.App-Phone .App-Phone-Waiter .Phone-Waiter-Box .Waiter-Box-Success img {
  width: 20px;
  display: block;
  margin: 0 auto;
  margin-bottom: .4rem
}

.App-Phone .App-Phone-Waiter .Phone-Waiter-Box .Waiter-Box-Success p {
  display: block;
  font-size: 10px;
  color: white;
  font-weight: bold;
}

.App-Phone .App-Phone-Person {
  position: absolute;
  top: 41%;
  right: 77%;
  width: 150px;
  height: 210px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('/person.png');
  display: none;
}

.App-Form {
  position: relative;
  width: 83%;
  margin: 0 auto;
  top: 3%;
  padding: 14px;
  box-sizing: border-box;
}

.App-Form .App-Form-Title {
  font-weight: bold;
  margin-top: 42px;
  font-size: 22px;
}

.App-Form .App-Form-Level {
  display: block;
  background-color: rgba(0, 0, 0, .03);
  border-radius: 14px;
  height: 26px;
  text-align: center;
  margin-top: 12px;
}

.App-Form .App-Form-Level li {
  display: inline-block;
  margin-top: 1px;
}

.App-Form .App-Form-Level li input[type="radio"] {
  display: none;
}

.App-Form .App-Form-Level li label {
  font-size: 8px;
  background: transparent;
  padding: 7px;
  border-radius: 13px;
  padding-top: 7px;
  padding-bottom: 7px;
  color: #00a99d;
  transition: all .3s;
  cursor: pointer;
}

.App-Form .App-Form-Level li label:hover {
  background-color: #00a99d;
  background: linear-gradient(90deg, rgba(34, 181, 115,1) 0%, rgba(34, 181, 115, 1) 35%, rgba(0, 169, 157, 1) 100%);
  color: white;
}

.App-Form .App-Form-Level li input[type="radio"]:checked + label {
  background-color: #00a99d;
  background: linear-gradient(90deg, rgba(34, 181, 115,1) 0%, rgba(34, 181, 115, 1) 35%, rgba(0, 169, 157, 1) 100%);
  color: white;
}

.App-Form .App-Form-SubTitle {
  margin-top: 24px;
  font-weight: normal;
  color: rgba(0, 0, 0, .8);
}

.App-Form .App-Form-Items {
  display: block;
  margin-top: 12px;
  height: 230px;
  overflow: auto;
}

.App-Form .App-Form-Items li {
  display: block;
  position: relative;
  border-top: 1px solid rgba(0, 0, 0, .15);
  padding-right: 16px;
  padding-top: 9px;
  padding-bottom: 9px;
}

.App-Form .App-Form-Items li input[type="checkbox"] {
  display: none;
}

.App-Form .App-Form-Items li input[type="checkbox"] + label {
  position: absolute;
  top: 27%;
  right: 0%;
  width: 10px;
  height: 10px;
  border: 2px solid #c6c6c6;
  border-radius: 100%;
  transition: background .3s;
}

.App-Form .App-Form-Items li input[type="checkbox"]:checked + label {
  border: 0;
  width: 14px;
  height: 14px;
  background-color: #00a99d;
  background: linear-gradient(90deg, rgba(34, 181, 115,1) 0%, rgba(34, 181, 115, 1) 35%, rgba(0, 169, 157, 1) 100%);
}

.App-Form .App-Form-Items .Form-Item-Avatar {
  width: 28px;
  height: 28px;
  position: absolute;
  background: #c6c6c6;
  border-radius: 100%;
  top: 14%;
  left: 1%;
}

.App-Form .App-Form-Items .Form-Item-Selector {
  display: block;
  height: 100%;
  width: 88%;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
}

.App-Form .App-Form-Items .Form-Item-Title {
  display: block;
  font-size: 10px;
  font-weight: bold;
}

.App-Form .App-Form-Items .Form-Item-SubTitle {
  display: block;
  font-size: 8px;
  font-weight: bold; 
  color: rgba(0, 0, 0, .7);
  margin-bottom: 5px;
  padding-top: 2px;
}

.App-Form .App-Form-Button {
  width: 100%;
  background-color: transparent;
  border-color: transparent;
  height: 42px;
  text-align: center;
  color: #39bbb1;
  font-weight: bold;
  font-size: 12px;
}


@media all and (min-width: 480px) {
  /*TODO*/
}

@media all and (min-width: 736px) {
  /*TODO*/
}

@media all and (min-width: 980px) {
  .App {
    padding-bottom: 0px;
  }

  .App-Elements {
    display: block;
  }

  .App-container{
    max-width: 1024px;
  }

  .App-menu {
    display: block;
  }

  .App-menu-movil {
    display: none;
  }

  .App-Background-yellow {
    width: 500px;
    left: 12%;
    top: -70%;
  }

  .App-Background-green {
    width: 500px;
    left: -5%; 
  }

  .App-Content {
    left: 128px;
    right: initial;
    max-width: 350px;
    margin: initial;
    margin-top: 64px;
  }

  .App-Content .App-title {
    font-size: 54px;
  }

  .App-Phone {
    position: absolute;
    transform: translate(-16px, 26px) rotate(8deg) rotateY(0deg);
    right: 10%;
    left: initial;
    margin: initial;
    margin-top: 0px;
    margin-bottom: 0px;
    -webkit-filter: drop-shadow(0px 5px 21px rgba(0, 0, 0, .6));
    filter: drop-shadow(0px 5px 21px rgba(0, 0, 0, .6));
  }

  .App-Phone:hover {
    transform: translate(0px, 5%) rotate(0deg) rotateY(0deg);
  }

  .App-Phone .App-Phone-Person {
    display: block;
  }
}

@media all and (min-width: 1280px) {
  /*TODO*/
}

@media all and (min-width: 1690px) {
  /*TODO*/
}